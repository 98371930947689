// @flow
import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

export default function ResponsiveDialog({
  open = false,
  onClose,
  children,
}: {
  open: boolean,
  onClose: Function,
  children: any,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xl"
    >
      <IconButton
        sx={{ position: 'absolute', top: '-5px', right: '-5px' }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
