/* eslint-disable react/jsx-newline */
// @flow
import React from 'react';

import type { MixedElement } from 'react';
import { Container, Typography, Paper, styled } from '@mui/material';
import Layout from '../../components/Layout';
import Heading from '../../components/Heading';
import { COMPANY } from '../../constants';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(4),
  color: theme.palette.text.secondary,
  height: '100%',
}));

export default function Terms(): MixedElement {
  return (
    <Layout>
      <Container>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Общи условия
        </Typography>

        <Item elevation={12}>
          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            ПРЕДМЕТ
          </Heading>

          <Heading
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Чл. 1. Настоящите общи условия са предназначени за регулиране на
            отношенията между {COMPANY.name}, ЕИК {COMPANY.eik}, със седалище и
            адрес на управление: {COMPANY.address} наричано по-долу за краткост
            ДОСТАВЧИК, и клиентите, наричани по-долу ПОЛЗВАТЕЛИ, на платформата
            за електронна търговия, наричана по-долу „{COMPANY.ecommerce}”.
          </Heading>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            ДАННИ ЗА ДОСТАВЧИКА
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Чл. 2. Информация съгласно Закона за електронната търговия и Закона
            за защита на потребителите: <br />
            Наименование на Доставчика: {COMPANY.name}
            <br />
            Седалище и адрес на управление {COMPANY.address}
            <br />
            Адрес за упражняване на дейността и адрес за отправяне на жалби от
            потребители: {COMPANY.address}
            <br />
            Данни за кореспонденция: {COMPANY.phone}
            <br />
            Вписване в публични регистри: ЕИК {COMPANY.eik}
            <br />
            <br />
            <strong>Надзорни органи:</strong>
            <br />
            <br />
            (1) Комисия за защита на личните данни
            <br />
            Адрес: гр. София, ул. „Проф. Цветан Лазаров” № 2,
            <br />
            тел.: (02) 940 20 46
            <br />
            факс: (02) 940 36 40
            <br />
            Email: kzld@government.bg, kzld@cpdp.bg
            <br />
            Уеб сайт: www.cpdp.bg
            <br />
            <br />
            (2) Комисия за защита на потребителите
            <br />
            Адрес: 1000 гр. София, пл.”Славейков” №4А, ет.3, 4 и 6,
            <br />
            тел.: 02 / 980 25 24
            <br />
            факс: 02 / 988 42 18
            <br />
            гореща линия: 0700 111 22
            <br />
            Уеб сайт: www.kzp.bg
            <br />
            Регистрация по Закона за данък върху добавената стойност
            <br />
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            ХАРАКТЕРИСТИКИ НА ПЛАТФОРМАТА
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Чл. 3. Онлайн Магазин за дървени табелки, e платформа за електронна
            търговия, достъпна на адрес в Интернет {COMPANY.ecommerce}, чрез
            която Ползвателите имат възможност да сключват договори за
            покупко-продажба и доставка на предлаганите от Доставчика в
            платформата стоки, включително следното:
          </Typography>

          <Typography
            component="ul"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ ml: 2, listStyle: 'circle' }}
          >
            <Typography
              component="li"
              variant="body1"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Да преглеждат стоките, техните характеристики, цени и условия за
              доставка;
            </Typography>

            <Typography
              component="li"
              variant="body1"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Да сключват с Доставчика договори за покупко-продажба и доставка
              на стоките, предлагани от в платформата {COMPANY.ecommerce};
            </Typography>

            <Typography
              component="li"
              variant="body1"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Да извършват всякакви плащания във връзка със сключените договори
              чрез предлаганите от платформата {COMPANY.ecommerce} средства за
              разплащане. Да получават информация за нови стоки, предлагани от
              Доставчика в платформата {COMPANY.ecommerce};
            </Typography>

            <Typography
              component="li"
              variant="body1"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Да бъдат уведомявани за правата, произтичащи от закона, предимно
              чрез интерфейса на платформата {COMPANY.ecommerce} в Интернет;
            </Typography>

            <Typography
              component="li"
              variant="body1"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Да упражняват правото си на отказ, когато е приложимо, по Закона
              за защита на потребителите.
            </Typography>
          </Typography>

          <br />

          <br />

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Чл. 4. Доставчикът в платформата {COMPANY.ecommerce} организира
            доставянето на стоките и гарантира правата на Ползвателите,
            предвидени в закона, в рамките на добросъвестността, възприетите в
            практиката, потребителското или търговското право критерии и
            условия.
            <br />
            <br />
            Чл. 5. (1) Ползвателите сключват с Доставчика в платформата{' '}
            {COMPANY.ecommerce} договор за покупко-продажба на стоките, на адрес{' '}
            {COMPANY.ecommerce} Договорът се сключва на български език и се
            съхранява в базата данни на Доставчика в платформата.
            <br />
            <br /> (2) По силата на сключения с Ползвателите договор за
            покупко-продажба на стоки, Доставчикът в платформата{' '}
            {COMPANY.ecommerce} се задължава да организира доставката и
            прехвърлянето на собствеността на Ползвателя на определените от него
            чрез интерфейса в платформата стоки. Ползвателите имат право да
            поправят грешки при въвеждането на информация не по-късно от
            отправяне на изявлението за сключване на договора Доставчика в
            платформата {COMPANY.ecommerce}
            <br />
            <br />
            (3) Ползвателите заплащат на Доставчика на платформата{' '}
            {COMPANY.ecommerce} възнаграждение за доставените стоки съгласно
            условията, определени в платформата {COMPANY.ecommerce} и настоящите
            общи условия. Възнаграждението е в размер на цената, обявена в
            платформата {COMPANY.ecommerce}
            <br />
            <br />
            Чл. 6. (1) Ползвателят и Доставчикът в платформата{' '}
            {COMPANY.ecommerce} се съгласяват, че всички изявления помежду им
            във връзка със сключването и изпълнението на договора за
            покупко-продажба могат да бъдат извършвани по електронен път и чрез
            електронни изявления по смисъла на Закона за електронния документ и
            електронния подпис и чл. 11 от Закона за електронната търговия.
            <br />
            <br />
            (2) Предполага се, че електронните изявления, извършени от
            Ползвателите на сайта са извършени от лицата, посочени в данните,
            предоставени от Ползвателя при извършване на покупка.
            <br />
            <br />
            Чл. 7. (1) С попълване на данните си в потребителската кошница и
            натискане на бутона “Поръчване”, Ползвателят декларира, че е
            запознат с тези общи условия, съгласен е с тяхното съдържание и се
            задължава безусловно да ги спазва. <br />
            <br />
            (2) Доставчикът потвърждава извършената от Ползвателя поръчка по
            електронна поща. Създава се акаунт на Ползвателя и между него и
            Доставчика възникват договорни отношения. <br />
            <br />
            (3) При извършване на поръчката Ползвателят се задължава да
            предостави верни и актуални данни. Ползвателят се задължава при
            промяна своевременно да актуализира данните, посочени в
            регистрацията или поръчката си.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            ТЕХНИЧЕСКИ СТЪПКИ ЗА СКЛЮЧВАНЕ НА ДОГОВОР ЗА ПОКУПКО-ПРОДАЖБА
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            <br /> Чл. 8. (1) Ползвателите използват предимно интерфейса
            страницата на Доставчика в платформата {COMPANY.ecommerce} за да
            сключват договори за покупко-продажба на предлаганите от
            доставчиците в платформата {COMPANY.ecommerce} стоки.
            <br />
            <br />
            (2) В случаите на поръчка на стоки без извършване на регистрация от
            страна на Ползвателя, последният приема тези общи условия в момента
            на доставката. Счита се, че Ползвателят е приел настоящите общи
            условия с приемането на доставката на стоките.
            <br />
            <br />
            Чл. 9. Ползвателите сключват договора за покупко-продажба на стоките
            в платформата {COMPANY.ecommerce} по следната процедура:
            <br />
            <br />
            (1) Влизане в системата за извършване на поръчки в платформата{' '}
            {COMPANY.ecommerce}
            <br />
            <br />
            (2) Избиране на една или повече от предлаганите от Доставчика в
            платформата {COMPANY.ecommerce} стоки и добавянето им към списък със
            стоки за покупка. <br />
            <br />
            (3) Предоставяне на необходимите данни за индивидуализация на
            Ползвателя като страна по договора. <br />
            <br />
            (4) Предоставяне на данни за извършване на доставката; <br />
            <br />
            (5) Избор на способ и момент за плащане на цената.
            <br />
            <br /> (6) Потвърждение на поръчката;
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            СЪДЪРЖАНИЕ НА ДОГОВОРА
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Чл. 10. (1) Доставчикът и Ползвателите сключват отделни договори за
            покупко-продажба на стоките, заявени от Ползвателите, независимо че
            са избрани с едно електронно изявление и от един списък със стоки за
            покупка. <br />
            <br />
            (2) Доставчикът може да организира заедно и едновременно доставката
            на поръчаните с отделните договори за покупко-продажба стоки.
            <br />
            <br /> (3) Правата на Ползвателите във връзка с доставените стоки се
            упражняват отделно за всеки договор за покупко-продажба.
            Упражняването на права във връзка с доставена стока не засяга и няма
            действие по отношение на договорите за покупко-продажба на другите
            стоки. В случай че Ползвателят има качеството на потребител по
            смисъла на Закона за защита на потребителите, упражняването на право
            на отказ от договора за покупко-продажба на определена стока не
            засяга договорите за покупко-продажба на другите стоки, доставени на
            потребителя. <br />
            <br />
            Чл. 11. При упражняване на правата по договора за покупко-продажба
            Ползвателят е задължен да посочва точно и недвусмислено договора и
            стоката, по отношение, на които упражнява правата. <br />
            <br />
            Чл. 12. Ползвателят може да плати цената за отделните договори за
            покупко-продажба наведнъж при извършване на поръчката на стоките или
            при тяхната доставка.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            ОСОБЕНИ КЛАУЗИ, КОИТО СЕ ПРИЛАГАТ СПРЯМО ЛИЦА, КОИТО ИМАТ КАЧЕСТВОТО
            ПОТРЕБИТЕЛ ПО СМИСЪЛА НА ЗАКОНА ЗА ЗАЩИТА НА ПОТРЕБИТЕЛИТЕ
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Чл. 13. Правилата на настоящия раздел VII от тези общи условия се
            прилагат единствено спрямо Ползватели, за които според данните,
            посочени за сключване на договора за покупко-продажба или при
            регистрацията в {COMPANY.ecommerce}, може да се направи извод, че са
            Потребители по смисъла на Закона за защита на потребителите, Закона
            за електронната търговия и/или на Директива 2011/83/ЕО на
            Европейския парламент и на Съвета от 25-ти октомври 2011 г. <br />
            <br />
            Чл. 14. (1) Основните характеристики на стоките, предлагани от
            Доставчика в платформата {COMPANY.ecommerce} са определени в профила
            на всяка стока в платформата {COMPANY.ecommerce}
            <br />
            <br />
            (2) Цената на стоките с включени всички данъци и такси се определя
            от Доставчика в платформата {COMPANY.ecommerce} в профила на всяка
            стока в платформата {COMPANY.ecommerce}
            <br />
            <br />
            (3) Стойността на пощенските или транспортните разходи, невключени в
            цената на стоките се определя от Доставчика в платформата{' '}
            {COMPANY.ecommerce} и се предоставя като информация на Ползвателите
            при избиране на стоките за сключване на договора за
            покупко-продажба;
            <br />
            <br />
            (4) Начините на плащане, доставка и изпълнение на договора се
            определят в настоящите общи условия и информацията, предоставена на
            Ползвателя посредством в механизмите в платформата{' '}
            {COMPANY.ecommerce}
            <br />
            <br /> (5) Информацията, предоставяна на Ползвателите по този член е
            актуална към момента на визуализацията й в платформата{' '}
            {COMPANY.ecommerce} преди сключването на договора за
            покупко-продажба.
            <br />
            <br />
            (6) Ползвателите се съгласяват, че цялата изискуема от Закона за
            защита на потребителите информация може да бъде предоставяна чрез
            интерфейса на платформата {COMPANY.ecommerce} или електронна поща.
            <br />
            <br /> Чл. 15. (1) Потребителят се съгласява, че доставчиците в
            платформата {COMPANY.ecommerce} имат право да приемат авансово
            плащане за сключените с Потребителя договори за покупко-продажба на
            стоки и тяхната доставка. <br />
            <br />
            (2) Потребителят избира самостоятелно дали да заплати на Доставчика
            в платформата {COMPANY.ecommerce} цената за доставка на стоките
            преди или в момента на доставката им. <br />
            <br />
            (3) В случай, че стойността на поръчката на Потребителя е
            равностойна или надвишава 15 000 лв., плащането се извършва само
            чрез превод или внасяне по платежна сметка на Доставчика. <br />
            <br />
            Чл. 16. (1) Потребителят има право, без да дължи обезщетение или
            неустойка и без да посочва причина, да се откаже от сключения
            договор в срок 14 дни, считано от датата на приемане на стоката от
            Доставчика. <br />
            <br />
            (2) Правото на отказ по ал. 1 не се прилага в следните случаи: за
            доставка на стоки, изработени по поръчка на потребителя или
            съобразно неговите индивидуални изисквания; за доставка на стоки,
            които поради своето естество могат да влошат качеството си или имат
            кратък срок на годност; за доставка на запечатани стоки, които са
            разпечатани след доставката им и не могат да бъдат върнати поради
            съображения, свързани с хигиената или защита на здравето; за
            доставка на стоки, които след като са били доставени и поради
            естество им са се смесили с други стоки, от които не могат да бъдат
            отделени; <br />
            <br />
            (3) Когато Потребителят е упражнил правото си на отказ от договора
            от разстояние или от договора извън търговския обект, Доставчикът
            възстановява всички суми, получени от потребителя, включително
            разходите за доставка, без неоправдано забавяне и не по-късно от 14
            дни, считано от датата, на която е бил уведомен за решението на
            потребителя да се откаже от договора. Доставчикът възстановява
            получените суми, като използва същото платежно средство, използвано
            от потребителя при първоначалната трансакция, освен ако потребителят
            е изразил изричното си съгласие за използване на друго платежно
            средство и при условие че това не е свързано с разходи за
            потребителя. <br />
            <br />
            (4) При упражняване на правото на отказ, разходите за връщане на
            доставените стоки се приспадат от сумите за възстановяване по ал. 3,
            освен в случаите, когато потребителят организира сам и за своя
            сметка връщането на стоките. Доставчикът няма задължение да
            възстанови допълнителните разходи за доставка на стоките, когато
            потребителят изрично е избрал начин на доставяне на стоките,
            различен от най-евтиния вид стандартна доставка, предлагана от
            Доставчика. <br />
            <br />
            (5) Потребителят се задължава да съхранява получените Доставчика в
            платформата стоки и да осигури запазването на тяхното качество и
            безопасност по време на срока по ал. 1.
            <br />
            <br /> Чл. 17. (1) Срокът на доставка на стоката е определен за
            всяка стока поотделно при сключване на договора с потребителя чрез
            сайта на Доставчика в платформата {COMPANY.ecommerce}
            <br />
            <br />
            (2) В случай че Потребителят и Доставчикът в платформата{' '}
            {COMPANY.ecommerce} не са определили срок за доставка, срокът на
            доставка на стоките е 30 календарни дни, считано от датата, следваща
            изпращането на поръчката на потребителя до Доставчика чрез сайта на
            Доставчика в платформата {COMPANY.ecommerce}
            <br />
            <br /> (3) Ако Доставчикът в платформата {COMPANY.ecommerce} не може
            да изпълни договора поради това, че не разполага с поръчаните стоки,
            той е длъжен да уведоми за това Потребителя и да възстанови
            платените от него суми.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            ИЗПЪЛНЕНИЕ НА ДОГОВОРА
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Чл. 19. (1) Доставчикът в платформата {COMPANY.ecommerce} може да
            организира доставката и предаването на стоката на Ползвателя от
            съответен куриер в определения при сключването на договора срок.{' '}
            <br />
            <br />
            (2) Ако срокът по ал. 1 не е изрично уговорен между страните при
            сключването на договора, Доставчикът организира доставката и
            предаването в разумен срок. <br />
            <br />
            Чл. 20. (1) Ползвателят трябва да прегледа стоката в момента на
            доставката и предаването и ако не отговаря на изискванията да
            уведоми за това незабавно Доставчика в платформата{' '}
            {COMPANY.ecommerce}
            <br />
            <br />
            (2) Ако Ползвателят не уведоми Доставчика в платформата{' '}
            {COMPANY.ecommerce} съгласно ал. 1 стоката се смята за одобрена като
            съответстваща на изискванията, освен за скрити недостатъци. Чл. 21.
            Доставчикът в платформата {COMPANY.ecommerce} не се задължава да
            осигурят необходимия сервиз за стоката. <br />
            <br />
            Чл. 22. За неуредените в този раздел случаи се прилагат правилата
            търговската продажба, определени в Търговския закон и Закона защита
            на потребителите.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            ЗАЩИТА НА ЛИЧНИТЕ ДАННИ
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Чл. 23. (1) Събирането, съхранението и обработването на лични данни
            се осъществява съобразно Политиката на {COMPANY.ecommerce}
            <br />
            <br />
            (2) Въведените от Ползвателите лични данни са обект на защита по
            Закона за защита на личните данни и Общия регламент 2016/679 като
            Доставчикът ги обработва за целите и в сроковете, предвидени в
            Политиката за поверителност на личните данни .<br />
            <br /> (3) При съгласие на Ползвателя с Политиката за поверителност
            на личните данни, Ползвателят изрично потвърждава, че е съгласен
            Доставчикът да съхранява информация или получава достъп до
            информацията, съхранена в крайното устройство на Ползвателя за
            изчерпателно предвидените в нея цели и срокове. Ползвателят се
            съгласява, че Доставчикът може да съхранява информация или получава
            достъп до информацията, съхранена в крайното устройство на
            Ползвателя и на други основания, посочени в Политиката за
            поверителност на личните данни.
            <br />
            <br /> (4) Ползвателят или Потребителят се съгласява, че Доставчикът
            на платформата {COMPANY.ecommerce} има право да изпраща по всяко
            време електронни съобщения към Ползвателя или Потребителя,
            включително и бюлетин или предложения за покупка на стоки, докато е
            налице регистрация на Ползвателя или Потребителя в електронния
            магазин на Доставчика в платформата {COMPANY.ecommerce}
            <br />
            <br />
            (5) Ползвателят или Потребителят се съгласява, че Доставчикът на
            платформата {COMPANY.ecommerce} има право да събира, съхранява и
            обработва данни да поведението на Ползвателя или Потребителя при
            използването на електронния магазин на Доставчика в платформата{' '}
            {COMPANY.ecommerce}. Ползвателят има право да възрази на
            съхраняването или достъпа до информацията по алинея 3 по
            предвидените в Политиката за поверителност на личните данни начини.{' '}
            <br />
            <br />
            Чл. 24. (1) Във всеки момент, Доставчикът в платформата{' '}
            {COMPANY.ecommerce} има право да изисква от Ползвателя да се
            легитимира и да удостовери достоверността на всяко едно от обявените
            по време на регистрацията обстоятелства и лични данни. <br />
            <br />
            (2) В случай, че по някаква причина Ползвателят е забравил или
            изгубил своите име и парола, Доставчикът на платформата{' '}
            {COMPANY.ecommerce} има право да приложи обявената “Процедура за
            изгубени или забравени имена и пароли”, достъпна на адрес:{' '}
            {COMPANY.ecommerce}
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            ПРЕКРАТЯВАНЕ
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Чл. 27. Настоящите общи условия и договора на Ползвателя с
            Доставчика в платформата {COMPANY.ecommerce} се прекратяват в
            следните случаи: при прекратяване и обявяване в ликвидация или
            обявяване в несъстоятелност на една от страните по договора; по
            взаимно съгласие на страните в писмен вид; при обективна
            невъзможност на някоя от страните по договора да изпълнява
            задълженията си; при изземване или запечатване на оборудването от
            държавни органи; в случай на заличаване на регистрацията на
            Ползвателя в платформата {COMPANY.ecommerce}. В този случай
            сключените, но неизпълнени договори за покупко-продажба остават в
            сила и подлежат на изпълнение; <br />
            <br />
            Чл. 28. Доставчикът има право по свое усмотрение, без да отправя
            предизвестие и без да дължи обезщетение да прекрати едностранно
            договора, в случай че установи, че Ползвателят използва платформата{' '}
            {COMPANY.ecommerce} в нарушение на настоящите общи условия,
            законодателството в Република България, общоприетите нравствени
            норми или общоприетите правила и практика в електронната търговия.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            ОТГОВОРНОСТ
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Чл. 29. Ползвателят се задължава да обезщети и да освободи от
            отговорност доставчиците в платформата {COMPANY.ecommerce} и
            Доставчика при съдебни искове и други претенции на трети лица
            (независимо дали са основателни или не), за всички щети и разходи (в
            това число адвокатски хонорари и съдебни разноски), произтичащи от
            или във връзка с <br />
            <br />
            (1) неизпълнение на някое от задълженията по този договор,
            <br />
            <br /> (2) нарушение на авторски, продуцентски, права на излъчване
            или други права върху интелектуалната или индустриална собственост,
            <br />
            <br />
            (3) неправомерно прехвърляне на други лица на правата, предоставени
            на Ползвателя, за срока и при условията на договора и (4) невярно
            деклариране наличието или отсъствието на качеството потребител по
            смисъла на Закона за защита на потребителите. <br />
            <br />
            Чл. 30. Доставчикът не носи отговорност в случай на непреодолима
            сила, случайни събития, проблеми в Интернет, технически или други
            обективни причини, включително и разпореждания на компетентните
            държавни органи. Чл. 31.
            <br />
            <br /> (1) Доставчикът не носи отговорност за вреди, причинени от
            Ползвателя на трети лица. <br />
            <br />
            (2) Доставчикът не носи отговорност за имуществени или неимуществени
            вреди, изразяващи се в пропуснати ползи или претърпени причинени на
            Ползвателят в процеса на използване или неизползване на{' '}
            {COMPANY.ecommerce} и сключване на договори за покупко-продажба с
            Доставчика.
            <br />
            <br /> (3) Доставчикът не носи отговорност за времето, през което
            платформата не е била достъпна поради непреодолима сила. <br />
            <br />
            (4) Доставчикът не носи отговорност за вреди от коментари, мнения и
            публикации под продуктите, новините и статиите в платформата{' '}
            {COMPANY.ecommerce}
            <br />
            <br />
            Чл. 32. (1) Доставчикът не носи отговорност в случай на преодоляване
            на мерките за сигурност на техническото оборудване и от това
            последва загуба на информация, разпространение на информация, достъп
            до информация, ограничаване на достъп до информация и други сходни
            последствия.
            <br />
            <br /> (2) Доставчикът не носи отговорност в случай на сключване на
            договор за покупко-продажба, предоставяне на достъп до информация,
            загуба или промяна на данни настъпили вследствие на фалшива
            легитимация на трето лице, което се представя за Ползвателя, ако от
            обстоятелствата може да се съди, че това лице е Ползвателя.
          </Typography>
        </Item>
      </Container>
    </Layout>
  );
}
