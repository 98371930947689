import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Homepage, { loader as homepageLoader } from './Homepage';
import Terms from './Terms';
import Privacy from './Privacy';
import Delivery from './Delivery';
import ThankYou from './ThankYou';
import AboutUs from './AboutUs';

export default createBrowserRouter([
  {
    path: '/',
    loader: homepageLoader,
    element: <Homepage />,
  },
  {
    path: '/terms',
    element: <Terms />,
  },
  {
    path: '/privacy',
    element: <Privacy />,
  },
  {
    path: '/delivery',
    element: <Delivery />,
  },
  {
    path: '/thank-you',
    element: <ThankYou />,
  },
  {
    path: '/about-us',
    element: <AboutUs />,
  },
]);
