// @flow

import { getAxiosConfig } from '../../axios/axiosSetup';

export type Product = {
  id: number,
  name: string,
  price: number,
  promoPrice: number,
  secondPrice: number,
};

export const getProducts = async (): Array<Product> => {
  const axios = getAxiosConfig();
  const { data: products } = await axios.get(`/products`);

  return products;
};

export default {
  getProducts,
};
