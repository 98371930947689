/* istanbul ignore file */
import React from 'react';
import StaticAxios from 'axios';
import { LRUCache } from 'lru-cache';
import { dequal as deepEqual } from 'dequal/lite';

// Important
// This file has been copied from axios-hooks library, due to the incompatibility with react 18

const actions = {
  REQUEST_START: 'REQUEST_START',
  REQUEST_END: 'REQUEST_END',
  REQUEST_CLEAR: 'REQUEST_CLEAR',
};

const DEFAULT_OPTIONS = {
  manual: false,
  useCache: true,
  ssr: true,
  autoCancel: true,
  useNative: false,
};

function isReactEvent(obj) {
  return obj && obj.nativeEvent && obj.nativeEvent instanceof Event;
}

function createCacheKey(config) {
  const cleanedConfig = { ...config };

  delete cleanedConfig.cancelToken;

  return JSON.stringify(cleanedConfig);
}

function configToObject(config) {
  if (typeof config === 'string') {
    return {
      url: config,
    };
  }

  return { ...config };
}

function useDeepCompareMemoize(value) {
  const ref = React.useRef();
  const signalRef = React.useRef(0);

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return [signalRef.current];
}

function makeUseAxios(configureOptions) {
  let cache;
  let axiosInstance;
  let defaultOptions;

  const ssrPromises = [];

  function resetConfigure() {
    cache = new LRUCache({ max: 500 });
    axiosInstance = StaticAxios;
    defaultOptions = DEFAULT_OPTIONS;
  }

  function configure(options = {}) {
    if (options.axios !== undefined) {
      axiosInstance = options.axios;
    }

    if (options.cache !== undefined) {
      cache = options.cache;
    }

    if (options.defaultOptions !== undefined) {
      defaultOptions = { ...DEFAULT_OPTIONS, ...options.defaultOptions };
    }
  }

  resetConfigure();
  configure(configureOptions);

  function loadCache(data) {
    cache.load(data);
  }

  async function serializeCache() {
    const ssrPromisesCopy = [...ssrPromises];

    ssrPromises.length = 0;

    await Promise.all(ssrPromisesCopy);

    return cache.dump();
  }

  function clearCache() {
    cache.reset();
  }

  function clearFromCache(config) {
    const cacheKey = createCacheKey(config);
    const response = cache.get(cacheKey);

    if (response) {
      cache.del(cacheKey);
    }
  }

  function tryStoreInCache(config, response) {
    if (!cache) {
      return;
    }

    const cacheKey = createCacheKey(config);
    const responseForCache = { ...response };

    delete responseForCache.config;
    delete responseForCache.request;

    cache.set(cacheKey, responseForCache);
  }

  function tryGetFromCache(config, options, dispatch) {
    if (!cache || !options.useCache) {
      return;
    }

    const cacheKey = createCacheKey(config);
    const response = cache.get(cacheKey);

    if (response && dispatch) {
      dispatch({ type: actions.REQUEST_END, payload: response });
    }

    // eslint-disable-next-line consistent-return
    return response;
  }

  function createInitialState(config, options) {
    const response = !options.manual && tryGetFromCache(config, options);

    return {
      loading: !options.manual && !response,
      error: null,
      ...(response ? { data: response.data, response } : null),
    };
  }

  function reducer(state, action) {
    switch (action.type) {
      case actions.REQUEST_START:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case actions.REQUEST_END:
        return {
          ...state,
          loading: false,
          ...(action.error
            ? {}
            : { data: action.payload.data || action.payload.status }),
          [action.error ? 'error' : 'response']: action.error
            ? action.payload.message
            : action.payload,
        };
      case actions.REQUEST_CLEAR:
        return { loading: false, error: null };
      default:
        return {
          ...state,
          loading: false,
          error: null,
        };
    }
  }

  async function executeRequest(config, dispatch) {
    try {
      dispatch({ type: actions.REQUEST_START });

      let response;

      if (config.useNative) {
        response = await fetch(config.url, {
          headers: config.headers,
          method: config.method,
        });

        if (response.status === 200) {
          const jsonResp = await response.json();

          response = { data: jsonResp, status: 200 };
        } else if (response.status >= 400) {
          throw Error(`Error: ${response.status}`, { cause: 'useNative' });
        }
      } else {
        response = await axiosInstance(config);
      }

      tryStoreInCache(config, response);

      dispatch({ type: actions.REQUEST_END, payload: response });

      return response;
    } catch (err) {
      if (!StaticAxios.isCancel(err)) {
        let payload = err;

        if (err.cause === 'useNative') {
          payload = {
            response: {
              data: { code: err.status, message: err.message },
            },
            message: err.message,
          };
        }

        dispatch({ type: actions.REQUEST_END, payload, error: true });
      }

      throw err;
    }
  }

  async function request(config, options, dispatch) {
    return (
      tryGetFromCache(config, options, dispatch) ||
      executeRequest(config, dispatch)
    );
  }

  function useAxios(_config, _options) {
    const config = React.useMemo(
      () => configToObject(_config),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useDeepCompareMemoize(_config),
    );

    const options = React.useMemo(
      () => ({ ...defaultOptions, ..._options }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useDeepCompareMemoize(_options),
    );

    const cancelSourceRef = React.useRef();

    const [state, dispatch] = React.useReducer(
      reducer,
      createInitialState(config, options),
    );

    if (typeof window === 'undefined' && options.ssr && !options.manual) {
      useAxios.ssrPromises.push(axiosInstance(config));
    }

    const cancelOutstandingRequest = React.useCallback(() => {
      if (cancelSourceRef.current) {
        cancelSourceRef.current.cancel();
      }
    }, []);

    const clearState = React.useCallback(() => {
      dispatch({ type: actions.REQUEST_CLEAR });
    }, []);

    const withCancelToken = React.useCallback(
      (cancelTokenConfig) => {
        if (options.autoCancel) {
          cancelOutstandingRequest();
        }

        cancelSourceRef.current = StaticAxios.CancelToken.source();

        // eslint-disable-next-line no-param-reassign
        cancelTokenConfig.cancelToken = cancelSourceRef.current.token;

        return cancelTokenConfig;
      },
      [cancelOutstandingRequest, options.autoCancel],
    );

    React.useEffect(() => {
      if (!options.manual) {
        request(withCancelToken(config), options, dispatch).catch(() => {});
      }

      return cancelOutstandingRequest;
    }, [config, options, withCancelToken, cancelOutstandingRequest]);

    const refetch = React.useCallback(
      (configOverride, refetchOptions) => {
        // eslint-disable-next-line no-param-reassign
        configOverride = configToObject(configOverride);

        return request(
          withCancelToken({
            ...config,
            ...(isReactEvent(configOverride) ? null : configOverride),
          }),
          { useCache: false, ...refetchOptions },
          dispatch,
        );
      },
      [config, withCancelToken],
    );

    return [state, refetch, clearState, cancelOutstandingRequest];
  }

  return Object.assign(useAxios, {
    ssrPromises,
    resetConfigure,
    configure,
    loadCache,
    serializeCache,
    clearCache,
    clearFromCache,
  });
}

const useAxios = makeUseAxios();

export default useAxios;
