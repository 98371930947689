// @flow

import type { AxiosCommonData } from '../../axios/useAxios';
import useAxios from '../../axios/useAxios';

export type Order = {
  name: string,
  email: string,
  address: string,
  city: string,
  deliveryType: string,
  items: any,
};

type OrderCreate = [
  {
    ...$Exact<AxiosCommonData>,
    data: Order,
  },
  Function,
  Function,
];

export const useOrderCreate = (): OrderCreate =>
  useAxios(
    {
      url: '/order',
      method: 'post',
    },
    {
      manual: true,
    },
  );

export default {
  useOrderCreate,
};
