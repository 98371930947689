// @flow
import React from 'react';
import type { MixedElement } from 'react';
import { Box, IconButton } from '@mui/material';
import ReactSwipe from 'react-swipe';
import {
  ArrowBackIosNewOutlined as ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
} from '@mui/icons-material';

type Props = {
  children: any,
  // eslint-disable-next-line react/require-default-props
  autoplay?: boolean,
  sliderRef: Object,
};

export default function Slider({
  children,
  autoplay = true,
  sliderRef,
}: Props): MixedElement {
  return (
    <Box sx={{ position: 'relative' }}>
      <ReactSwipe
        className="carousel"
        childCount={children.length}
        swipeOptions={{
          auto: autoplay ? 3000 : 0,
          continuous: true,
        }}
        ref={sliderRef}
      >
        {children}
      </ReactSwipe>

      <IconButton
        color="primary"
        aria-label="prev"
        onClick={() => sliderRef.current.prev()}
        sx={{
          position: 'absolute',
          left: '5px',
          top: '49%',
        }}
      >
        <ArrowBackIosNewOutlinedIcon />
      </IconButton>

      <IconButton
        color="primary"
        aria-label="next"
        onClick={() => sliderRef.current.next()}
        sx={{
          position: 'absolute',
          right: '5px',
          top: '49%',
        }}
      >
        <ArrowForwardIosOutlinedIcon />
      </IconButton>
    </Box>
  );
}
