// @flow
import React from 'react';
import type { MixedElement } from 'react';
import { Grid, Typography, Link, Container } from '@mui/material';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
} from '@mui/icons-material';
import { ScrollRestoration } from 'react-router-dom';
import { COMPANY } from 'constants';

function Copyright() {
  return (
    <>
      <Grid item xs={12} sm={4}>
        <Link
          href={COMPANY.facebook}
          target="_blank"
          rel="noopener"
          aria-label="Tabelkite.com в facebook"
        >
          <FacebookIcon />
        </Link>

        <Link
          href={COMPANY.instagram}
          target="_blank"
          rel="noopener"
          aria-label="Tabelkite.com в instagram"
          sx={{ m: 1 }}
        >
          <InstagramIcon />
        </Link>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography
          variant="body2"
          color="text.secondary"
          align="left"
          sx={(theme) => ({
            mt: 1,
            [theme.breakpoints.up('xs')]: {
              mt: 0,
            },
          })}
        >
          Copyright ©
          <Link color="inherit" href="/" aria-label="tabelkite.com">
            tabelkite.com
          </Link>
          &nbsp;
          {new Date().getFullYear()}.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={(theme) => ({
            textAlign: 'left',
            mt: 1,
            [theme.breakpoints.up('sm')]: {
              textAlign: 'right',
              mt: 0,
            },
          })}
        >
          Дизайн и изработка&nbsp;
          <Link
            color="inherit"
            href="https://eurekasoft.eu"
            target="_blank"
            rel="noopener"
            aria-label="IT Agency EurekaSoft"
          >
            EurekaSoft
          </Link>
        </Typography>
      </Grid>
    </>
  );
}

type Props = {
  children: any,
  // eslint-disable-next-line react/require-default-props
  bottomMenu?: boolean,
};

export default function Layout({
  children,
  bottomMenu = true,
}: Props): MixedElement {
  return (
    <>
      <ScrollRestoration />

      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6, pl: 2, pr: 2 }}
      >
        {children}
      </Container>

      <Container
        maxWidth="lg"
        component="footer"
        sx={{
          borderTop: (t) => `1px solid ${t.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          {bottomMenu && (
            <Grid
              item
              xs={12}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
              })}
            >
              <Link
                href="/"
                underline="hover"
                color="text.secondary"
                aria-label="Начало"
              >
                Начало
              </Link>

              <Link
                href="/about-us"
                underline="hover"
                color="text.secondary"
                aria-label="За Tabelkite.com"
              >
                За нас
              </Link>

              <Link
                href="/terms"
                underline="hover"
                color="text.secondary"
                aria-label="Общи условия"
              >
                Общи условия
              </Link>

              <Link
                href="/privacy"
                underline="hover"
                color="text.secondary"
                aria-label="Политика за поверителност"
              >
                Политика за поверителност
              </Link>

              <Link
                href="/delivery"
                underline="hover"
                color="text.secondary"
                aria-label="Условия за доставка"
              >
                Доставка
              </Link>
            </Grid>
          )}

          <Copyright />
        </Grid>
      </Container>
    </>
  );
}
