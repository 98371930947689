// @flow
import React from 'react';

import type { MixedElement } from 'react';
import { Container, Typography, Paper, styled } from '@mui/material';
import Layout from '../../components/Layout';
import Heading from '../../components/Heading';
import { COMPANY } from '../../constants';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(4),
  color: theme.palette.text.secondary,
  height: '100%',
}));

export default function Privacy(): MixedElement {
  return (
    <Layout>
      <Container>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Политика за поверителност
        </Typography>

        <Item elevation={12}>
          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            1. Общи положения
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Тази политика за поверителност определя начина, по който „
            {COMPANY.name}“ (наричано по-долу „Магазинът“) събира, използва и
            обработва личните данни на потребителите (наричани по-долу
            „Потребителите“) на онлайн магазина на Магазина за дървени табелки
            за врата.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            2. Определения
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            {`За целите на настоящата политика за поверителност, посочените по-долу
          термини имат следното значение: „Магазин“ – „${COMPANY.name}“,
          дружество с ограничена отговорност, регистрирано в Търговския регистър
          към Агенцията по вписванията с ЕИК ${COMPANY.eik}, със седалище и адрес
          на управление:
          ${COMPANY.address}. „Потребител“ – всяко физическо лице, което използва
          онлайн магазина на Магазина за покупка на дървени табелки за врата.
          „Лични данни“ – всяка информация, която може да бъде използвана за
          идентифициране на физическо лице, включително, но не само: име, адрес,
          имейл адрес, телефонен номер, IP адрес, данни за плащане и др.`}
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            3. Събиране на лични данни
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Магазинът събира лични данни на Потребителите в следните случаи: при
            регистрация на Потребителя в онлайн магазина; при извършване на
            покупка от онлайн магазина; при осъществяване на контакт с Магазина
            по телефон, имейл или друг начин; при използване на функциите на
            онлайн магазина, които изискват предоставяне на лични данни, като
            например коментари, отзиви и др. Магазинът събира следните лични
            данни на Потребителите: име и фамилия; имейл адрес; телефонен номер;
            адрес за доставка; данни за плащане; IP адрес.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            4. Цели на обработване на личните данни
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Магазинът обработва личните данни на Потребителите за следните цели:
            предоставяне на услуги на Потребителите в онлайн магазина;
            изпълнение на договори за покупко-продажба на дървени табелки за
            врата; комуникация с Потребителите; подобряване на качеството на
            услугите на Магазина; маркетингови цели.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            5. Срок на съхранение на личните данни
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Магазинът съхранява личните данни на Потребителите за срок от 5
            години от датата на прекратяване на регистрацията им в онлайн
            магазина.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            6. Предаване на лични данни на трети лица
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Магазинът може да предоставя личните данни на Потребителите на трети
            лица, като например доставчици на куриерски услуги, доставчици на
            платежни услуги, доставчици на маркетингови услуги и др., само в
            случаите, когато това е необходимо за изпълнение на договор с
            Потребителя или за изпълнение на законово задължение на Магазина.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            7. Права на Потребителите
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            {`Потребителите имат следните права във връзка с личните си данни, които
          са обработвани от Магазина: право на достъп до личните си данни; право
          на коригиране на личните си данни; право на изтриване на личните си
          данни; право на ограничаване на обработването на личните си данни;
          право на преносимост на личните си данни; право на възражение срещу
          обработването на личните си данни; право да не бъдат обект на
          автоматизирано вземане на решения; право да се обжалва пред Комисията
          за защита на личните данни. Потребителите могат да упражнят правата
          си, като подадат писмено заявление до Магазина на адрес: ${COMPANY.email}.`}
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            8. Защита на личните данни
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Магазинът предприема необходимите технически и организационни мерки
            за защита на личните данни на Потребителите от неправомерна
            обработка.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            9. Актуализации на политиката за поверителност
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Магазинът може да актуализира настоящата политика за поверителност
            по всяко време. Актуализираната политика за поверителност ще бъде
            публикувана на сайта на Магазина.
          </Typography>
        </Item>
      </Container>
    </Layout>
  );
}
