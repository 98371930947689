// @flow
import React from 'react';

import type { MixedElement } from 'react';
import { Container, Typography, styled, Paper } from '@mui/material';
import Layout from '../../components/Layout';
import Heading from '../../components/Heading';
import { COMPANY } from '../../constants';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(4),
  color: theme.palette.text.secondary,
  height: '100%',
}));

export default function Delivery(): MixedElement {
  return (
    <Layout>
      <Container>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Условия за доставка, плащане и рекламация
        </Typography>

        <Item elevation={12}>
          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Начини на доставка
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Доставяме само на територията на България.
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            При успешно финализиране на поръчка през сайта {COMPANY.ecommerce},
            започваме да изработваме 3D модела, който ще бъде изпратен до Вас в
            рамките на 1 работен ден. След одобрение от Ваша страна започва
            процеса по изработка на самата табела който отнема 3-4 работни дни в
            зависимост от размера и избраният вариант.
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Общото време за изпълнение на поръчката е приблизително 3-5 работни
            дни.
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            При поръчка от {COMPANY.ecommerce} можете да получите продуктите си
            на територията на България със помощта на куриерските фирми Еконт
            Експрес.
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            ВАЖНО! Моля имайте в предвид, че цената на доставката е
            предварително зададена в нашата система и е възможно цената да се
            различава от изчислената от спедиторската компания която сте
            избрали. Причината е че всяка табела е уникална и тежеста на всяка
            една е различна.
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Mаксималното време за престой на пратка в офис на Еконт е 7 дни. Ако
            клиентът не потърси пратката си в посочения срок или я откаже, тя се
            връща директно към подателя.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Начини на плащане
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Наложен платеж - Продуктът заплащате на куриера при доставката.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Рекламация
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Въпреки че онлайн магазин {COMPANY.ecommerce} полага всички усилия,
            продаваните продукти да бъдат с най-високо качество, може да се
            случи така, че да получите дефектен/грешен продукт. В такава
            ситуация имате право в 30-дневен срок да направите рекламация,
            връщане или замяна.
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Как да направите рекламация?
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            {`Mожете да направите рекламация удобно и лесно - чрез електронна поща:
          ${COMPANY.email} или да подадете сигнал на тел: ${COMPANY.phone}`}
          </Typography>

          <Heading
            component="h3"
            variant="h5"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Смяна и връщане
          </Heading>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
          >
            {`Ние се стараем, нашите клиенти да остават доволни от обслужването на
          ${COMPANY.ecommerce}, затова предлагаме удобна система за връщане на
          стоките. За тази цел е нужно да се направят няколко стъпки: 1) Ако
          опаковката на продукта не е повредена, в срок 30 дни от закупването
          вие можете да подадете заявка за отказ, като се свържете с нас чрез
          електронна поща: ${COMPANY.email} или да подадете сигнал на тел: ${COMPANY.phone}.
          Вие можете да върнете стока в случай на дефект.`}
          </Typography>
        </Item>
      </Container>
    </Layout>
  );
}
