// @flow
import React from 'react';
import type { MixedElement } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';

type NameplateOptionProps = {|
  label: string,
  onChange: Function,
  value: string,
  name: string,
  products: Array<{
    id: number,
    name: string,
    price: number,
    promoPrice: number,
    secondPrice: number,
  }>,
  // eslint-disable-next-line react/require-default-props
  error?: boolean,
|};

export default function NameplateOption({
  label,
  value,
  onChange,
  name,
  products = [],
  error = false,
}: NameplateOptionProps): MixedElement {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>

      <Select
        error={error}
        name={name}
        value={value || ''}
        defaultValue=""
        label={label}
        onChange={onChange}
        inputProps={{ 'aria-label': 'Тип на табелката' }}
        sx={{
          '& .MuiInputBase-input': {
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
          },
        }}
      >
        {products.map((product) => (
          <MenuItem key={product.id} value={product.id}>
            {product.thumb && (
              <img src={product.thumb} width="85px" alt={product.name} />
            )}

            <Typography sx={{ ml: 2 }}>{product.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
