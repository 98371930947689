// @flow

import { getAxiosConfig } from '../../axios/axiosSetup';

export type GetDelivery = {
  [string]: number,
};

export const getDeliveries = async (): Array<GetDelivery> => {
  const axios = getAxiosConfig();
  const { data: deliveries } = await axios.get(`/delivery`);

  return deliveries;
};

export default {
  getDeliveries,
};
