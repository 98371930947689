import { object, string, boolean } from 'yup';

const ERROR_MESSAGES = {
  required: 'задължително поле',
  email: 'невалиден email адрес',
  phone: 'невалиден телефонен номер',
};

const FormSchema = object({
  nameplateText: string().required(ERROR_MESSAGES.required),
  nameplateOption: string().required(ERROR_MESSAGES.required),
  name: string().required(ERROR_MESSAGES.required),
  email: string().email(ERROR_MESSAGES.email).required(ERROR_MESSAGES.required),
  phone: string()
    .matches(/^(((\+|00)359)|(0))(8[789](\d){7})$/gm, ERROR_MESSAGES.phone)
    .required(ERROR_MESSAGES.required),
  address: string().required(ERROR_MESSAGES.required),
  city: string().required(ERROR_MESSAGES.required),
  hasSecondNameplate: boolean(),
  secondNameplateText: string().when('hasSecondNameplate', {
    is: true,
    then: (schema) => schema.required(ERROR_MESSAGES.required),
    otherwise: (schema) => schema.nullable(),
  }),
  // secondNameplateOption: string().when('hasSecondNameplate', {
  //   is: true,
  //   then: (schema) => schema.required(ERROR_MESSAGES.required),
  //   otherwise: (schema) => schema.nullable(),
  // }),
});

export default FormSchema;
