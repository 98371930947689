// @flow
/* istanbul ignore file */

// $FlowFixMe
import axios from 'axios';
import useAxios from './useAxiosInstance';

export type AxiosData = {
  loading: boolean,
  error: ?Object,
};

const { REACT_APP_API_URL } = process.env;

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getAxiosConfig = () => {
  const axiosConfig = {
    baseURL: REACT_APP_API_URL,
    headers: {
      ...defaultHeaders,
    },
  };

  const axiosInstance = axios.create(axiosConfig);

  return axiosInstance;
};

const axiosSetup = () => {
  const axiosInstance = getAxiosConfig();
  const { configure } = useAxios;

  configure({ axios: axiosInstance });
};

export default axiosSetup;
