// @flow
import { useEffect, useState } from 'react';

const { REACT_APP_RECAPTCHA_KEY } = process.env;

const showBadge = () => {
  if (!window.grecaptcha) return;
  window.grecaptcha.ready(() => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];

    if (!badge) return;
    badge.style.display = 'block';
    badge.style.zIndex = '1';
  });
};

const hideBadge = () => {
  if (!window.grecaptcha) return;
  window.grecaptcha.ready(() => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];

    if (!badge) return;
    badge.style.display = 'none';
  });
};

export default function useReCaptcha(): {
  reCaptchaLoaded: boolean,
  generateReCaptchaToken: (action: string) => Promise<string>,
} {
  const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false);

  // Load ReCaptcha script
  useEffect(() => {
    if (typeof window === 'undefined' || reCaptchaLoaded) return;
    if (window.grecaptcha) {
      showBadge();
      setReCaptchaLoaded(true);

      return;
    }
    const script = document.createElement('script');

    script.async = true;
    script.src = `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_KEY}`;
    script.addEventListener('load', () => {
      setReCaptchaLoaded(true);
      showBadge();
    });
    document.body.appendChild(script);
  }, [reCaptchaLoaded]);

  // Hide badge when unmount
  useEffect(() => hideBadge, []);

  // Get token
  const generateReCaptchaToken = async (action: string): Promise<string> =>
    new Promise((resolve, reject) => {
      if (!reCaptchaLoaded) {
        reject(new Error('ReCaptcha not loaded'));

        return;
      }
      if (typeof window === 'undefined' || !window.grecaptcha) {
        setReCaptchaLoaded(false);
        reject(new Error('ReCaptcha not loaded'));

        return;
      }
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(REACT_APP_RECAPTCHA_KEY, { action })
          .then((token: string) => {
            resolve(token);
          });
      });
    });

  return { reCaptchaLoaded, generateReCaptchaToken };
}
