export const products = {
  simple: {
    id: 1,
    price: 29.99,
    promoPrice: 24.99,
    secondPrice: 17.49,
    name: 'Обикновенна',
  },
  house: {
    id: 2,
    price: 34.99,
    promoPrice: 29.99,
    secondPrice: 20.99,
    name: 'Къща',
  },
  house_2: {
    id: 2,
    price: 34.99,
    promoPrice: 29.99,
    secondPrice: 20.99,
    name: 'Къща',
  },
  love: {
    id: 3,
    price: 34.99,
    promoPrice: 29.99,
    secondPrice: 20.99,
    name: 'Любов',
  },
};

export const deliveries = {
  econt: {
    price: 6.5,
  },
  address: {
    price: 8.0,
  },
};

export const GALLERY_ITEMS = [
  { thumb: '/assets/a1.webp', src: '/assets/a1.webp' },
  {
    thumb: '/assets/ivanovi_555.webp',
    src: '/assets/ivanovi_555.webp',
  },
  {
    thumb: '/assets/karamchevi_741.webp',
    src: '/assets/karamchevi_741.webp',
  },
  {
    thumb: '/assets/hristovi_741.webp',
    src: '/assets/hristovi_741.webp',
  },
];

export const SLIDER_ITEMS = [
  {
    type: 'img',
    src: '/assets/gavazovi_741.webp',
    alt: 'Класическа дървена табелка за врата',
  },
  {
    type: 'img',
    src: '/assets/haralambevi_741.webp',
    alt: 'Дървена табелка за врата тип къща',
  },
  {
    type: 'img',
    src: '/assets/dudinovi_741.webp',
    alt: 'Дървена табелка за врата тип любов',
  },
  {
    type: 'img',
    src: '/assets/karamchevi_741.webp',
    alt: 'Дървена табелка за врата тип къща',
  },
  {
    type: 'img',
    src: '/assets/arabadzievi_741.webp',
    alt: 'Дървена табелка за врата тип дърво',
  },
  {
    type: 'video',
    src: '/video/nameplate_light.mp4',
    alt: 'Светеща дървена табелка за врата тип любов',
  },
];

export const COMPANY = {
  name: 'ИМЕ НА ФИРМА',
  eik: 'ЕИК НА ФИРМА',
  address: 'АДРЕС НА ФИРМА',
  email: 'info@tabelkite.com',
  phone: 'ТЕЛЕФОН ЗА ВРЪЗКА',
  ecommerce: 'https://tabelkite.com',
  facebook: 'https://www.facebook.com/tabelkitecom',
  instagram: 'https://www.instagram.com/tabelkitecom',
};
