/* eslint-disable react/jsx-newline */
// @flow
import React from 'react';

import type { MixedElement } from 'react';
import { Container, Link, Paper, Typography, styled } from '@mui/material';
import Layout from '../../components/Layout';
import { COMPANY } from '../../constants';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(4),
  color: theme.palette.text.secondary,
  height: '100%',
}));

export default function AboutUs(): MixedElement {
  return (
    <Layout>
      <Container>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          За нас
        </Typography>
        <Item elevation={12}>
          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ fontWeight: 'bold' }}
          >
            Занаятчийска традиция, съчетана с модерен дизайн
          </Typography>
          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ mt: 2 }}
          >
            Онлайн магазинът "tabelkite.com" е мястото където вие ще промените
            архитектурният облик на вашият дом. Ние сме посветени на изработката
            на ръчно изработени дървени табелки от орех, съчетавайки традиционни
            техники за дърворезба с модерен дизайн.
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ mt: 2 }}
          >
            Всяка табелка е уникална.
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ mt: 2 }}
          >
            Всяка табелка, която предлагаме, е изработена с внимание към детайла
            и прецизност. Използваме само висококачествена орехова дървесина,
            която е не само красива, но и здрава и устойчива. Предлагаме богато
            разнообразие от дизайни, от класически до модерни, както и
            възможност за изработка на поръчка по ваш дизайн.
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ mt: 2 }}
          >
            Персонализирани подаръци
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ mt: 2 }}
          >
            Дървените табелки от орех са идеалният подарък за всеки повод. Те са
            красиви, практични и носят сантиментална стойност. Можем да
            персонализираме вашата табелка с име, дата, послание или друг текст,
            за да я направите още по-специална.
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ mt: 2 }}
          >
            Защо да изберете "Дърворезба от орех"?
          </Typography>

          <Typography
            component="ul"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ ml: 2, listStyle: 'circle' }}
          >
            <Typography
              component="li"
              variant="body1"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Ръчно изработени табелки с внимание към детайла
            </Typography>

            <Typography
              component="li"
              variant="body1"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Висококачествена орехова дървесина
            </Typography>

            <Typography
              component="li"
              variant="body1"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Богато разнообразие от дизайни
            </Typography>

            <Typography
              component="li"
              variant="body1"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Възможност за изработка на поръчка
            </Typography>

            <Typography
              component="li"
              variant="body1"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Персонализирани подаръци
            </Typography>
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ mt: 2 }}
          >
            Поръчайте вашата дървена табелка от орех още днес!
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ mt: 2 }}
          >
            Свържете се с нас: <br />
            Имейл: info@tabelkite.com
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ mt: 2 }}
          >
            Следете ни:
            <br />
            Facebook:{' '}
            <Link
              href={COMPANY.facebook}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Tabelkite.com в facebook"
            >
              {COMPANY.facebook}
            </Link>
            <br />
            Instagram:{' '}
            <Link
              href={COMPANY.instagram}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Tabelkite.com в instagram"
            >
              {COMPANY.instagram}
            </Link>
          </Typography>

          <Typography
            component="p"
            variant="body1"
            align="left"
            color="text.primary"
            gutterBottom
            sx={{ mt: 2 }}
          >
            Дърворезба от орех - красота, изящество и трайност.
          </Typography>
        </Item>
      </Container>
    </Layout>
  );
}
