// @flow
import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import ResponsiveDialog from 'components/ResponsiveDialog';

export default function Gallery({ items = [] }: { items: array }) {
  const [open, setOpen] = useState<boolean>(false);
  const [zoomImageSrc, setZoomImageSrc] = useState<?string>(null);

  const handleZoom = (item) => (event) => {
    event.preventDefault();
    setZoomImageSrc(item.src);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setZoomImageSrc(null);
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={2} direction="row" sx={{ mt: 1 }}>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item xs={12} sm={3} key={`gallery_item_${index}`}>
            <Button onClick={handleZoom(item)}>
              <img
                src={item.thumb}
                alt="Персонална дървена табелка за врата"
                width="100%"
                style={{ minHeight: '130px' }}
              />
            </Button>
          </Grid>
        ))}
      </Grid>

      <ResponsiveDialog open={open} onClose={handleDialogClose}>
        {zoomImageSrc && (
          <img
            src={zoomImageSrc}
            width="100%"
            alt="Персонална дървена табелка за врата"
          />
        )}
      </ResponsiveDialog>
    </>
  );
}
