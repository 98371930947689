// @flow

import useAxios from './useAxiosInstance';

export type AxiosCommonData = {
  loading: boolean,
  error: ?Object,
};

type AxiosData = [
  {
    ...$Exact<AxiosCommonData>,
    data: any,
  },
  Function,
  Function,
];

type Config = {
  url: string,
  method: string,
};

type Options = {
  manual?: boolean,
};

const useAxiosWrapper = (config: Config, options?: Options): AxiosData =>
  useAxios(config, options);

export default useAxiosWrapper;
