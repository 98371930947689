// @flow
import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Collapse,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MixedElement } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import Slider from 'components/Slider';
import Gallery from 'components/Gallery';
import { GALLERY_ITEMS, SLIDER_ITEMS } from 'constants';
import { getProducts } from 'entities/product';
import { getDeliveries } from 'entities/delivery';
import Form from '../../components/Form';
import Layout from '../../components/Layout';
import Heading from '../../components/Heading';
import { useOrderCreate } from '../../entities/order';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  height: '100%',
}));

const Video = styled('video')(({ theme }) => ({
  objectFit: 'cover',
  height: '234px',
  [theme.breakpoints.up('sm')]: {
    height: '558px',
  },
}));

export async function loader() {
  const products = await getProducts();
  const deliveries = await getDeliveries();

  return { products, deliveries };
}

export default function Homepage(): MixedElement {
  const { products, deliveries } = useLoaderData();

  const reactSwipeEl = useRef();
  const errorRef = useRef();
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();
  const [{ data, loading, error }, createOrder] = useOrderCreate();

  const handleSubmit = async (formData) => {
    try {
      await createOrder({ data: formData });
    } catch (e) {
      console.error(e);
      if (errorRef.current) {
        errorRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if (data && data.name) {
      navigate('/thank-you', { state: { user: data.name } });
    }
  }, [data, navigate]);

  return (
    <Layout>
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Табелки от дърво
      </Typography>

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12} sm={8}>
          <Item elevation={12}>
            <Slider sliderRef={reactSwipeEl}>
              {SLIDER_ITEMS.map((sliderItem) =>
                sliderItem.type === 'img' ? (
                  <img
                    src={`${sliderItem.src}`}
                    alt={`${sliderItem.alt}`}
                    width="100%"
                  />
                ) : (
                  <Video muted autoPlay loop="loop">
                    <track />

                    <meta itemProp="name" content="tabelkite.com" />

                    <meta
                      itemProp="description"
                      content={`${sliderItem.alt}`}
                    />

                    <source src={`${sliderItem.src}`} type="video/mp4" />
                  </Video>
                ),
              )}
            </Slider>

            <Heading
              component="h2"
              variant="h5"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Табелка за входна врата
            </Heading>

            <Typography
              variant="body1"
              align="left"
              color="text.secondary"
              component="p"
            >
              Тя е важен елемент от архитектурния облик на всеки дом и не само
              дава информация за името на обитателите, но и може да бъде и
              произведение на изкуството.
            </Typography>

            <Typography
              variant="body1"
              align="left"
              color="text.secondary"
              component="p"
            >
              Дървената табела с дърворезба е един от най-популярните варианти
              за украса на входната врата. Тя е класическа и изискана, а
              разнообразието от мотиви и стилове позволява да се намери
              подходяща табела за всеки вкус.
            </Typography>

            <Typography
              variant="body1"
              align="left"
              color="text.secondary"
              component="p"
              sx={(t) => ({
                fontSize: '22px',
                fontWeight: 'bold',
                textAlign: 'center',
                color: t.palette.error.main,
              })}
            >
              {`САМО СЕГА OT `}

              <span style={{ textDecoration: 'line-through' }}>
                {products[0].price} ЛВ.
              </span>

              {` ${products[0].promoPrice} ЛВ.`}
            </Typography>

            <Heading
              component="h2"
              variant="h5"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Персонален дизайн
            </Heading>

            <Typography
              variant="body1"
              align="left"
              color="text.secondary"
              component="p"
            >
              В допълнение към стандартните табелки за врата, които предлагаме в
              нашия онлайн магазин, ние можем да изработим и табелки по
              персонален дизайн.
            </Typography>

            <br />

            <Typography
              variant="body1"
              align="left"
              color="text.secondary"
              component="p"
            >
              Ако имате уникална идея за табела за врата, която не намирате в
              нашия каталог, моля, не се колебайте да се свържете с нас. Ние ще
              се радваме да работим с Вас, за да създадем табела, която да
              отговаря на Вашите изисквания.
            </Typography>

            <br />

            <Typography
              variant="body1"
              align="left"
              color="text.secondary"
              component="p"
            >
              За да направите поръчка за табела по персонален дизайн, моля,
              изпратете имейл на info@tabelki.com. В имейл адреса посочете
              следната информация:
            </Typography>

            <Typography component="ul" sx={{ listStyle: 'disc', pl: 2 }}>
              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                component="li"
              >
                Вид на табелката (например, табела с надпис, табела с
                изображение, табела с гравюра и др.)
              </Typography>

              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                component="li"
              >
                Размер на табелката
              </Typography>

              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                component="li"
              >
                Текст или изображение, което искате да бъде изобразено на
                табелката
              </Typography>

              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                component="li"
              >
                Материал, от който искате да бъде изработена табелката
              </Typography>
            </Typography>

            <Typography
              variant="body1"
              align="left"
              color="text.secondary"
              component="p"
            >
              В рамките на няколко работни дни ще Ви изпратим оферта за
              изработката на табелката.
            </Typography>

            <br />

            <Typography
              variant="body1"
              align="left"
              color="text.secondary"
              component="p"
            >
              Очакваме с нетърпение да работим с Вас!
            </Typography>

            <Heading
              component="h2"
              variant="h5"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Вашите дизайни
            </Heading>

            <Gallery items={GALLERY_ITEMS} />

            <Heading
              component="h2"
              variant="h5"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Изработка
            </Heading>

            {isMobile && !seeMore && (
              <>
                <Typography
                  variant="body1"
                  align="left"
                  color="text.secondary"
                  component="p"
                >
                  ...
                </Typography>

                <Button onClick={() => setSeeMore(true)}>виж още</Button>
              </>
            )}

            <Collapse in={!isMobile || seeMore}>
              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                component="p"
              >
                Всяка табелка за входни врати се прави на основа одобрен от вас
                3D дизайн, който ще получите на вашият имейл след направена
                поръчка. Времето за изработка е от 3 до 5 дни и варира спрямо
                избраните от вас опции.
              </Typography>

              <Heading
                component="h2"
                variant="h5"
                align="left"
                color="text.primary"
                gutterBottom
              >
                Размери
              </Heading>

              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                component="p"
              >
                Размерите на Вашата табелка зависят от избраният от Вас вариант.
                Ориентировачно размерите са в диапазона 30х20х1.5
                см.(широчина/височина/дебелина)
              </Typography>

              <Heading
                component="h2"
                variant="h5"
                align="left"
                color="text.primary"
                gutterBottom
              >
                Монтаж
              </Heading>

              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                component="p"
              >
                Табелките за входни врати от дърво с дърворезба се монтират с
                помощта на винтове или лепило.
              </Typography>

              <Heading
                component="h2"
                variant="h5"
                align="left"
                color="text.primary"
                gutterBottom
              >
                Грижа и поддръжка
              </Heading>

              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                component="p"
              >
                Дървените табели с дърворезба изискват редовна грижа и
                поддръжка, за да запазят своята красота и дълготрайност. Те
                трябва да се почистват редовно с мека кърпа и неабразивен
                почистващ препарат. За да се предпази дървото от изсъхване и
                напукване, то трябва да се импрегнира с подходящо защитно
                средство.
              </Typography>

              <br />

              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                component="p"
              >
                Табелката за входна врата от дърво с дърворезба е красив и
                стилен начин да украсите дома си и да създадете неповторимо
                впечатление у гостите си.
              </Typography>
            </Collapse>
          </Item>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Item elevation={12} ref={errorRef}>
            {error && (
              <Alert severity="error">
                Нещо се обърка. Моля попълнете всички задължителни полета и
                опитайте отново.
              </Alert>
            )}

            <Form
              onSubmit={handleSubmit}
              hasServerError={error !== null}
              loading={loading}
              products={products}
              deliveries={deliveries}
            />

            <Alert variant="outlined" severity="info">
              <Typography component="h4" variant="h6" gutterBottom>
                30-дневна Гаранция за Връщане на Парите
              </Typography>
              В случай, че не останете доволни от табелката, можете да я върнете
              обратно в 30-дневен срок след получаването й, а ние ще ви върнем
              парите, които сте платили за нея.
            </Alert>
          </Item>
        </Grid>
      </Grid>
    </Layout>
  );
}
