// @flow

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Heading = styled(Typography)(() => ({
  '&::before': {
    content: '""',
    width: '65px',
    height: '2px',
    marginTop: '12px',
    marginBottom: '2px',
    display: 'block',
  },
  '&::after': {
    content: '""',
    width: '65px',
    height: '2px',
    marginTop: '12px',
    marginBottom: '2px',
    background: 'rgba(0, 0, 0, 0.23)',
    display: 'block',
  },
}));

export default Heading;
