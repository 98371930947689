// @flow
import * as React from 'react';
import { Paper, Typography, Container, Box } from '@mui/material';

import { Check as CheckIcon } from '@mui/icons-material';

import type { MixedElement } from 'react';
import { useLocation } from 'react-router-dom';
import { COMPANY } from 'constants';
import Layout from '../../components/Layout';

export default function ThankYou(): MixedElement {
  const location = useLocation();

  return (
    <Layout bottomMenu={false}>
      <Container
        disableGutters
        maxWidth="sm"
        sx={{ pt: 8, pb: 6, pl: 2, pr: 2 }}
      >
        <Paper sx={{ pt: 8, pb: 6, pl: 2, pr: 2 }} elevation={12}>
          <Box align="center">
            <CheckIcon color="primary" fontSize="large" />
          </Box>

          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Благодарим Ви за направената поръчка от tabelkite.com!
          </Typography>

          <br />

          <Typography
            variant="body1"
            align="left"
            color="text.secondary"
            component="p"
          >
            Уважаеми {location.state && location.state.user},
          </Typography>

          <br />

          <Typography
            variant="body1"
            align="left"
            color="text.secondary"
            component="p"
          >
            За нас е удоволствие да Ви обслужваме и да Ви помогнем да намерите
            подходящите дървени табелки за врата за Вашия дом.
          </Typography>

          <br />

          <Typography
            variant="body1"
            align="left"
            color="text.secondary"
            component="p"
          >
            Вашата поръчка е приета и ще бъде обработена възможно най-скоро. Наш
            съдружник ще се свърже с вас за потвърждение на поръчката. Ще Ви
            уведомим за статуса на поръчката по имейл.
          </Typography>

          <br />

          <Typography
            variant="body1"
            align="left"
            color="text.secondary"
            component="p"
          >
            Очакваме с нетърпение да Ви изпратим Вашата поръчка и да Ви видим
            отново като наш клиент.
          </Typography>

          <br />

          <Typography
            variant="body1"
            align="left"
            color="text.secondary"
            component="p"
          >
            Ако имате каквито и да е въпроси, можете да ни пишете на имейл:
            {COMPANY.email}
          </Typography>

          <br />

          <Typography
            variant="body1"
            align="left"
            color="text.secondary"
            component="p"
          >
            С уважение, <br />
            Екипът на tablekite.com
          </Typography>
        </Paper>
      </Container>
    </Layout>
  );
}
