// @flow
import {
  RouterProvider,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, GlobalStyles } from '@mui/material';

import router from './routes';
import axiosSetup from './axios/axiosSetup';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] },
>((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { href, ...other } = props;

  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});

function App() {
  axiosSetup();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
        />

        <CssBaseline />

        <RouterProvider
          router={router}
          onChange={() => {
            console.warn('here');
            window.scrollTo(0, 0);
          }}
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
